import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Vor einem Jahr machte ich den Kurs `}<span lang="en">{`„Full Stack for Front-End Engineers”`}</span>{` von
`}<a parentName="p" {...{
        "href": "https://frontendmasters.com"
      }}>{`Frontend Masters`}</a>{`. Ich richtete meinen eigenen Webserver ein, kaufte eine Domain und startete
mithilfe von `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`Gatsby`}</a>{` einen Blog über Webentwicklung. 12 Monate und 18 Blogartikel später möchte
ich das Jahr Revue passieren lassen und mir Ziele für 2023 setzen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABwaFc7tJBP//EABcQAQEBAQAAAAAAAAAAAAAAAAEAEQL/2gAIAQEAAQUCOrdVJ6yG6xf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAQAGPwKkOiUyn//EABwQAAICAgMAAAAAAAAAAAAAAAERADEhUUFhof/aAAgBAQABPyElMhoCoqnGx5GdolUTuGOWQXcyZvoz/9oADAMBAAIAAwAAABDs7//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8Qcwh//8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxCHV//EAB0QAQACAgIDAAAAAAAAAAAAAAERIQAxQbFhcaH/2gAIAQEAAT8QVRLJNuWesWlAm0jLSvXnNGpWpw75xIMy/Kx6IEINphUM26TrP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Mehrere Leute feiern und stoßen mit Weingläsern an.",
          "title": "Mehrere Leute feiern und stoßen mit Weingläsern an.",
          "src": "/static/55fbd0a65c88f5661a1bc3c59b3704d2/e5166/pexels-cottonbro-studio-raise-glasses.jpg",
          "srcSet": ["/static/55fbd0a65c88f5661a1bc3c59b3704d2/f93b5/pexels-cottonbro-studio-raise-glasses.jpg 300w", "/static/55fbd0a65c88f5661a1bc3c59b3704d2/b4294/pexels-cottonbro-studio-raise-glasses.jpg 600w", "/static/55fbd0a65c88f5661a1bc3c59b3704d2/e5166/pexels-cottonbro-studio-raise-glasses.jpg 1200w", "/static/55fbd0a65c88f5661a1bc3c59b3704d2/b17f8/pexels-cottonbro-studio-raise-glasses.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © cottonbro studio / pexels.com`}</em></p>
    <p>{`Das regelmäßige Schreiben macht mir großen Spaß. Tatsächlich bin ich 2006 nach Wien übersiedelt, um Journalismus zu
studieren. Ich habe auch für Medienunternehmen gearbeitet, aber es war nicht das Richtige für mich. Also bin ich zum Coden
zurückgekehrt. Jedenfalls fühlt es sich gut an, meine beiden Leidenschaften irgendwie vereinen zu können.`}</p>
    <h2>{`Was ich dieses Jahr gelernt habe`}</h2>
    <ul>
      <li parentName="ul">{`Erstens, wie ich meinen eigenen Webserver einrichte, eine Domain verwalte, ein SSL-Zertifikat erstelle und so weiter. Es war ein
gutes Gefühl, meine Komfortzone zu verlassen und etwas Neues auszuprobieren!`}</li>
      <li parentName="ul">{`Wie ich meinen Build/Deployment-Workflow mit `}<a parentName="li" {...{
          "href": "https://github.com/features/actions"
        }}>{`GitHub Actions`}</a>{` automatisieren kann.`}</li>
      <li parentName="ul">{`Dass ich gerne mit neuen Features wie etwa dem nativen `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` HTML-Element oder
der `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` CSS-Pseudo-Klasse experimentiere. Ich liebe die Herausforderung, das Konzept und die
Funktionalität möglichst einfach zu erklären und mit einer Demo zu veranschaulichen.`}</li>
      <li parentName="ul">{`Ich habe mich mit `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/RSS"
        }}>{`RSS`}</a>{` vertraut gemacht und gelernt, einen eigenen RSS-Feed bereitzustellen.
Ihr könnt meinen `}<a parentName="li" {...{
          "href": "https://www.oidaisdes.org/rss.xml"
        }}>{`Blog hier abonnieren`}</a>{`.`}</li>
    </ul>
    <h2>{`Meine Lieblings-Blogartikel`}</h2>
    <p>{`Es ist schwer, aus den 18 Blogartikeln, die ich in diesem Jahr geschrieben habe, meine Favoriten auszuwählen. Deshalb habe ich einfach
drei Beiträge ausgewählt, die ich für besonders relevant und spannend halte:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.oidaisdes.org/de/common-aria-mistakes.de/"
        }}>{`„Häufige Probleme mit ARIA und wie ihr diese vermeidet”`}</a>{`: Ich finde es toll,
dass mehr und mehr Unternehmen die Bedeutung von Barrierefreiheit erkennen. Leider übertreiben es einige mit ARIA-Attributen und
verschlimmern oft die Barrierefreiheit ihrer Website. Ich hoffe, mein Blogbeitrag hilft Entwickler:innen, ARIA besser zu verstehen und
schlechte Praktiken zu vermeiden.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.oidaisdes.org/de/cascade-layers-in-angular.de/"
        }}>{`„CSS Cascade Layers in Angular verwenden”`}</a>{`: 2022 war ein fantastisches
Jahr für CSS. Zu den neuen, von modernen Browsern unterstützten Features zählen CSS Cascade Layers. Sie sind ein mächtiges Werkzeug zur
Ordnung von CSS-Styles und helfen, nervige Konflikte aufgrund der Spezifität oder Reihenfolge der Deklaration zu vermeiden.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.oidaisdes.org/de/lights-off-how-blind-users-navigate.de/"
        }}>{`„Licht aus! Wie blinde Menschen im Internet surfen”`}</a>{`:
Vor ein paar Wochen habe ich für einen neuen Kunden einen Workshop zum Thema Barrierefreiheit gehalten. Ich öffnete meine
`}<a parentName="li" {...{
          "href": "https://alexlehner86.github.io/come-to-the-dark-side/home"
        }}>{`Demo-Seite`}</a>{`, färbte alles schwarz und benutzte einen Screenreader, um auf
der Website zu navigieren. Die Teilnehmer waren wirklich erstaunt. Einer von ihnen sagte: `}<ItalicText mdxType="ItalicText">{`„Ich wusste gar nicht, dass blinde
Menschen das Internet überhaupt nutzen können!”`}</ItalicText>{` Das hat mir wieder einmal gezeigt: Die Menschen müssen die Auswirkungen von
Behinderungen selbst erfahren, um Barrierefreiheit wirklich zu schätzen.`}</li>
    </ol>
    <h2>{`Ziele und Erwartungen für 2023`}</h2>
    <p>{`Als Web-Entwickler bin ich schon sehr gespannt auf das neue Jahr. Es kommen eine Menge neuer Features auf uns zu, die uns das Leben
leichter machen sollten. Als ein Mensch auf diesem Planeten hoffe ich einfach nur, dass der schreckliche Krieg in der Ukraine endet.
Und dass die Menschheit endlich den Klimawandel ernsthaft bekämpft.`}</p>
    <p>{`Wie auch immer, hier ist meine Liste an Zielen für 2023 sowie Themen, über die ich im kommenden Jahr schreiben möchte:`}</p>
    <ul>
      <li parentName="ul">{`Ich will Interaktionen in sozialen Netzwerken in `}<a parentName="li" {...{
          "href": "https://www.freecodecamp.org/news/how-to-use-webmentions-with-gatsby-beginners-guide/"
        }}>{`Webmentions`}</a>{`
umwandeln und in meinem Blog anzeigen.`}</li>
      <li parentName="ul">{`Flexbox oder Grid? Ich möchte mein Wissen über CSS-Layouts vertiefen und mit neuen Features
wie `}<a parentName="li" {...{
          "href": "https://ishadeed.com/article/learn-css-subgrid/"
        }}>{`CSS Subgrid`}</a>{` experimentieren.`}</li>
      <li parentName="ul">{`Ich möchte mich als Requirements Engineer und Software Architekt weiterentwickeln und über meine Erfahrungen schreiben.`}</li>
      <li parentName="ul">{`An Entwickler-Konferenzen teilnehmen und vielleicht mal `}<a parentName="li" {...{
          "href": "https://www.freecodecamp.org/news/complete-guide-to-giving-your-first-conference-talk/"
        }}>{`einen Vortrag halten`}</a>{`.
Lokale Events veranstalten wäre auch cool.`}</li>
      <li parentName="ul">{`Zu guter Letzt: Weiter über Barrierefreiheit schreiben.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      